import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import service1 from '../assets/images/service1.jpg'
import service2 from '../assets/images/service2.jpg'
import service3 from '../assets/images/service3.jpg'
import service4 from '../assets/images/service4.jpg'
import service5 from '../assets/images/service5.jpg'
import service6 from '../assets/images/service6.jpg'

const Index = () => {
    return (
        <Layout>
            <Banner />

            <div id="main">
                <section id="one" className="tiles">
                    <article style={{ backgroundImage: `url(${service1})` }}>
                        <header className="major">
                            <h3>Matrícula inicial</h3>
                            <p>Registro de un vehículo nuevo ante la secretaría de tránsito</p>
                        </header>
                        <Link to="/vehicles" className="link primary"></Link>
                    </article>
                    <article style={{ backgroundImage: `url(${service2})` }}>
                        <header className="major">
                            <h3>Traspaso</h3>
                            <p>Cambio de un propietario a otro</p>
                        </header>
                        <Link to="/vehicles" className="link primary"></Link>
                    </article>
                    <article style={{ backgroundImage: `url(${service3})` }}>
                        <header className="major">
                            <h3>Certificado de tradición</h3>
                            <p>Historial de todos los trámites que se le han realizado a un vehículo</p>
                        </header>
                        <Link to="/vehicles" className="link primary"></Link>
                    </article>
                    <article style={{ backgroundImage: `url(${service4})` }}>
                        <header className="major">
                            <h3>Licencias de conducción</h3>
                            <p>Expedición del permiso para conducir cualquier tipo de vehículo</p>
                        </header>
                        <Link to="/licenses" className="link primary"></Link>
                    </article>
                    <article style={{ backgroundImage: `url(${service5})` }}>
                        <header className="major">
                            <h3>Toma de improntas</h3>
                            <p>Procedimiento en el cual se capturan los números de motor, serie y chasis de un vehículo</p>
                        </header>
                        <Link to="/other_services" className="link primary"></Link>
                    </article>
                    <article style={{ backgroundImage: `url(${service6})` }}>
                        <header className="major">
                            <h3>Enseñanza de automovilismo</h3>
                            <p>Se orienta al usuario a un centro de enseñanza automovilista (CEA) enlazado a nuestra oficina</p>
                        </header>
                        <Link to="/external_links" className="link primary"></Link>
                    </article>
                </section>
                <section id="two">
                    <div className="inner">
                        <header className="major">
                            <h2>Apreciado cliente</h2>
                        </header>
                        <p>Nuestros servicios están pensados para facilitar tu vida, ahorrándote tiempo en largas filas, papeleos y procesos tediosos. Nuestro único objetivo es que te dediques a las verdaderas cosas importantes en la vida como tu trabajo, tu familia o tu diversión.
                            <br /> Consulta nuestros servicios, no inviertas más tiempo en trámites e inviértelo en lo que tú quieras.
                        </p>
                        <ul className="actions">
                            <li><Link to="/services" className="button">Nuestros servicios</Link></li>
                        </ul>
                    </div>
                </section>
            </div>

        </Layout>
    )
}

export default Index