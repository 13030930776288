import React from 'react'
import { Link } from 'gatsby'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Hola, <br />somos Tramites Dorado</h1>
            </header>
            <div className="content">
                <p>Empresa dedicada a realizar tus trámites de vehículos y licencias <br />
                ante las secretarías de tránsito.</p>
                <ul className="actions">
                    <li><Link to="/services" className="button ">Nuestros servicios</Link></li>
                </ul>
            </div>
        </div>
    </section>
)

export default Banner
